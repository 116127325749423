import React, { Component } from "react";
import { HashRouter, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import {
  ProtectedRoute,
  UnauthenticatedRoute,
  isAuthenticated,
} from "routes/protected-route";
import { blackMamba } from "./styles/theme";
import { GlobalStyles } from "./styles/global";
import "./scss/style.scss";

const loading = (
  <div className=" text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() =>
  import("./containers/layouts/layout-manager")
);

// Pages
const Auth = React.lazy(() => import("pages/unauthorized/unauth-main"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: null,
      loading: true,
      player: false,
    };
  }

  componentDidMount() {
    // SET DEFAULT THEME
    this.setState({ theme: blackMamba });
  }

  render() {
    return (
      <>
        {this.state.theme ? (
          <ThemeProvider theme={this.state.theme}>
            <GlobalStyles />
            <HashRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <UnauthenticatedRoute
                    exact
                    path="/login"
                    name="Login Page"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    exact
                    path="/auth"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/auth/:mode"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/auth/:mode/:username"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/register/event/:event_id"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/confirm/:username"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />
                  <UnauthenticatedRoute
                    path="/forgot-password"
                    component={Auth}
                    render={(props) => <Auth {...props} />}
                  />

                  <ProtectedRoute
                    path="/"
                    component={TheLayout}
                    isAuthenticated={isAuthenticated()}
                    render={(props) => <TheLayout {...props} />}
                  />
                </Switch>
              </React.Suspense>
            </HashRouter>
          </ThemeProvider>
        ) : null}
      </>
    );
  }
}

export default App;
